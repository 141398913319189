import { likeRegExp } from '@/offline/database'

export default {
  _selectNota (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.nota)
      .innerJoin(
        tables.tnota,
        tables.nota.idtnota.eq(tables.tnota.idtnota)
      )
  },
  async selectNota (Vue, filter, search, sorter, page, identificador, idtabla, extra = []) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.nota.titulo.match(reQ),
          tables.nota.nota_texto.match(reQ),
        )
      )
    }
    let whereExtra = []
    if (extra.length > 0) {
      for (const extraFilter of extra) {
        whereExtra.push(
          Vue.$offline.db.op.and(
            tables.nota.idtabla.eq(extraFilter.idtabla),
            tables.nota.identificador.eq(extraFilter.identificador),
          )
        )
      }
    }
    whereExtra.push(
      Vue.$offline.db.op.and(
        tables.nota.idtabla.eq(idtabla),
        tables.nota.identificador.eq(identificador),
      )
    )
    where.push(
      Vue.$offline.db.op.and(
        tables.nota.estado.gt(0),
        ...whereSearch,
        ...whereFilter,
        Vue.$offline.db.op.or(
          ...whereExtra
        )
      )
    )
    return [
      await this._selectNota(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .exec(),
      []
    ]
  },
  async selectNotaRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return await this._selectNota(Vue)
      .where(tables.nota.idnota.in(pks))
      .exec()
  },
}
